import AboutImage from '../../assets/amias.jpeg'
import CV from '../../assets/cv.pdf'
import {HiDownload} from 'react-icons/hi'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
  return (
    <section id="about" data-aos="fade-in">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={AboutImage} alt="About Image" />
                </div>
            </div>
            <div className="about__right">
                <h2>About Me</h2>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                <p>
                    Hi, my name is Amias Yaska. I'm a software developer and AWS Certified Cloud Practitioner. I specialize in building and hosting robust, user-friendly web apps tailored to your needs.
                </p>
                <p>
                    With over 4 years of experience in the software development industry, I’m committed to delivering exceptional projects that exceed my clients’ expectations. Let’s collaborate to turn your vision into reality—get in touch today, and let’s make something amazing together!                
                </p>
                
                {/* <a href={CV} download className='btn primary'>Download CV <HiDownload/></a> */}
                {/* <div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="ef563702-24c5-4b36-bd56-49ea22a35481" data-share-badge-host="https://www.credly.com"></div><script type="text/javascript" async src="//cdn.credly.com/assets/utilities/embed.js"></script> */}
                {/* <div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="ef563702-24c5-4b36-bd56-49ea22a35481" data-share-badge-host="https://www.credly.com"></div><script type="text/javascript" async src="//cdn.credly.com/assets/utilities/embed.js"></script> */}
            </div>
        </div>
    </section>
  )
}

export default About