import Games from '../../assets/store_games.png'
import Bags from '../../assets/bags.png'
import Table from '../../assets/periodic_table.png'
import Business from '../../assets/businesses.png'
import Nelson from '../../assets/nelson.PNG'
// import Image6 from '../../assets/project6.jpg'
// import Image7 from '../../assets/project7.jpg'
// import Image8 from '../../assets/project8.jpg'
// import Image9 from '../../assets/project9.jpg'

const data = [
    {
        id: 1,
        category: 'Landing Page',
        image: Nelson,
        title: "Engineering Solutions Landing Page",
        desc: "A responsive, modern landing page for an engineering company, highlighting services, expertise, and client success stories with clear call-to-actions.",
        demo: 'https://nelson-design.vercel.app/',
    },
    {
        id: 2,
        category: 'Web Scraping',
        image: Games,
        title: "Steam Game Information",
        desc: "This Python project utilizes the popular web scraping framework Scrapy to build a spider named StoreSpider. This spider specifically targets the Steam Store homepage and gathers information about the games listed there.",
        github: 'https://github.com/AmiasYaska/GAMES'
    },
    
    {
        id: 3,
        category: 'Web Scraping',
        image: Bags,
        title: "Backpack scraper",
        desc: "This Scrapy spider is designed to scrape product information for backpacks from the API of target website. It then stores the scraped data of all the 50 pages in json format.",
        github: 'https://github.com/AmiasYaska/SHOPPING'
    },
    
    {
        id: 4,
        category: 'Web Scraping',
        image: Table,
        title: "Periodic Table",
        desc: "This Scrapy spider code extracts information about elements from the periodic table available at the National Center for Biotechnology Information (NCBI) website. The information is stored in json and csv.",
        github: 'https://github.com/AmiasYaska/PERIODIC-TABLE'
    },

    {
        id: 5,
        category: 'Web Scraping',
        image: Business,
        title: "California Businesses for sale",
        desc: "This bot scrapes business listings for sale in California. Extracts details like title, image URL, description, price, location, and some contact info (broker company, name, phone, photo) . Over 5000 businesses are collected and stored in json and csv.",
        github: 'https://github.com/AmiasYaska/BIZBUYSCRAPER'
    },
    
    // {
    //     id: 3,
    //     category: 'Web Scraping',
    //     image: Image6,
    //     title: "Project Title Three (Web Scraping)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    //     demo: 'http://egatortutorials.com',
    //     github: 'https://github.com/AmiasYaska'
    // },
    // {
    //     id: 4,
    //     category: 'Web Scraping',
    //     image: Image7,
    //     title: "Project Title Four (Web Scraping)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    //     demo: 'http://egatortutorials.com',
    //     github: 'https://github.com/egattor'
    // },
    // {
    //     id: 8,
    //     category: 'backend',
    //     image: Image8,
    //     title: "Project Title Eight (Backend)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    //     demo: 'http://egatortutorials.com',
    //     github: 'https://github.com/egattor'
    // },
    // {
    //     id: 9,
    //     category: 'Web Scraping',
    //     image: Image9,
    //     title: "Project Title Nine (Frontend)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    //     demo: 'http://egatortutorials.com',
    //     github: 'https://github.com/egattor'
    // }
]


export default data