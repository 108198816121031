// import { FaAws } from "react-icons/fa6";
import { FaPython } from "react-icons/fa";
// import {FaServer} from 'react-icons/fa';
import {FaWind} from "react-icons/fa";
import { FaCode } from "react-icons/fa";


/* import {AiFillAppstore} from 'react-icons/ai'    */


const data = [
    {
        id: 1, icon: <FaCode/>, title: 'Full Stack Web Development', 
        desc: "I specialize in building secure, scalable, and interactive web applications using Ruby on Rails for the back-end, Tailwind CSS for sleek, responsive designs, and StimulusJS along with Hotwire (Turbo and Stimulus) for real-time, minimal-JavaScript interactions.",
    },
    
    {
        id: 2, icon: <FaWind />, title: 'Landing Page Design with Tailwind CSS', 
        desc: "I design and develop high-converting landing pages that captivate your audience and drive conversions using Tailwind CSS. My focus is creating responsive designs that prioritize user experience and brand consistency." 
    },
    {
        id: 3, icon: <FaPython />, title: 'Web Scraping', 
        desc: "Say goodbye to tedious manual scraping. I automate the process of extracting the precise data you need, in the format you want, faster than you ever thought possible." 

    },
    
/*    {
        id: 4, icon: <AiFillAppstore/>, title: 'App Development', desc: 'Unlike other developers in the market, I will building an app that runs on both IOS and Android devices without any extra cost to you.'
    }   */
]


export default data